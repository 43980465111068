import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./layout.css";

export default function Layout({ children, className }) {
  return (
    <div className={classNames(className)}>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link
        href="https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,900&display=swap"
        rel="stylesheet"
      />
      <div className="header-banner">
        <h2>
          <span className="logo-one">one</span>
          <span className="logo-one logo-gray">{"_"}</span>
          <span className="logo-two">humble</span>
          <span className="logo-two logo-gray">{"."}</span>
          <span className="logo-three">developer</span>
          <span className="logo-three logo-white">()</span>
          <span className="logo-five">↵</span>
        </h2>
      </div>
      <div className="area-content">{children}</div>
    </div>
  );
}

Layout.propTypes = {
  className: PropTypes.string,
};
